import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, take } from 'rxjs/operators';
import { ToastrService } from '@windmill/ng-windmill';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { CaptchaService } from '../_services/captcha.service';
import { CaptchaStatus } from '../_enums/captcha.enum';
import { commonRoutingConstants } from '../_constants/common-routing.constants';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
	private isRefreshingToken = false;
	private toastBackgroundMode = 'toast-light';
	private tokenRefreshedSubject = new BehaviorSubject<boolean>(false);
	private shownErrorCodes = new Set<number>();

	constructor(
		private readonly toastrService: ToastrService,
		private translateService: TranslateService,
		private authService: AuthService,
		private router: Router,
		private captchaService: CaptchaService
	) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				const customErrorCode = error.error;
				if (error instanceof ErrorEvent) {
					this.showToast('generic');
				} else if (customErrorCode) {
					return this.handleCustomError(error, request, next);
				} else {
					return this.handleBackendError(error, request, next);
				}

				return throwError(() => error);
			})
		);
	}

	private handleCustomError(
		error: HttpErrorResponse,
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		const customErrorCode = error.error;
		switch (customErrorCode) {
			case 40004:
				this.handleCaptcha(CaptchaStatus.INVALID_CREDENTIALS);
				break;
			case 40009:
				this.handleCaptcha(CaptchaStatus.CREATED);
				break;
			case 40017:
				return this.handleUnauthorized(request, next);
			case 40019:
				this.router.navigate([commonRoutingConstants.login]);
				break;
			default:
				break;
		}

		if (customErrorCode === 40027) {
			this.showToast(customErrorCode.toString(), false);
			return throwError(() => error);
		}

		if (!this.shownErrorCodes.has(customErrorCode)) {
			this.showToast(customErrorCode.toString());
			this.shownErrorCodes.add(customErrorCode);
		}

		return throwError(() => error);
	}


	private handleBackendError(
		error: HttpErrorResponse,
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		const status = error.status;
		switch (status) {
			case 401: {
				if (this.authService.isLoggedIn) {
					return this.handleUnauthorized(request, next);
				}
				break;
			}
			default:
				break;
		}

		this.showToast(status.toString());
		return throwError(() => error);
	}

	private handleCaptcha(status: CaptchaStatus): void {
		this.captchaService.displayCaptchaSubject.next(status);
	}

	private handleUnauthorized(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this.isRefreshingToken) {
			return this.tokenRefreshedSubject.pipe(
				filter(Boolean),
				take(1),
				concatMap(() => next.handle(this.addToken(request)))
			);
		}

		this.isRefreshingToken = true;

		// Reset here so that the following requests wait until the token
		// comes back from the refreshToken call.
		this.tokenRefreshedSubject.next(false);

		return this.authService.refreshToken().pipe(
			concatMap((res: any) => {
				localStorage.setItem('JWT_TOKEN', res.accessToken);

				this.tokenRefreshedSubject.next(true);
				return next.handle(this.addToken(request));
			}),
			catchError((err) => {
				this.authService.logout();
				this.router.navigate([commonRoutingConstants.login]);
				return throwError(() => err);
			}),
			finalize(() => {
				this.isRefreshingToken = false;
			})
		);
	}

	private addToken(req: HttpRequest<unknown>): HttpRequest<unknown> {
		const token = localStorage.getItem('JWT_TOKEN');
		return token ? req.clone({
			setHeaders: { Authorization: 'Bearer ' + token.replace(/^"(.*)"$/, '$1') },
			withCredentials: true
		}) : req;
	}

	private showToast(message: string, isError: boolean = true): void {
		const toastType = isError ? 'error' : 'info';
		const toasterMessage = this.translateService.instant(`errors.${message}`);

		this.toastrService[toastType](`<p>${toasterMessage}</p>`, '', {
			toastBackground: this.toastBackgroundMode,
			enableHtml: true,
			progressBar: true,
			tapToDismiss: true,
			timeOut: 8000,
			extendedTimeOut: 8000
		});
	}

}
