<ng-container *ngIf="!shouldDisplaySuccessfulPasswordChangeDialog; else successfullChangePassword">
    <centric-set-new-password>
        <ng-container title>
            <frontend-logo-title [title]='"changePassword.title"'></frontend-logo-title>
            <hr class="forgot-password-divider">
        </ng-container>
        <ng-container form>
            <form centric-complex-form class="form-set-new-password" [formGroup]="form" (ngSubmit)="changePassword()">
                <centric-form-item>
                    <centric-form-label>{{ 'general.password' | translate }}</centric-form-label>
                    <centric-form-control frontendRequiredInput>
                        <windmill-input ariaAttributes formControlName="password" type="password"
                            autocomplete="off" [placeholder]="'genericFields.password.passwordPlaceholder' | translate"
                            [maxLength]="256" [ariaLabelTranslation]="'genericFields.password.passwordPlaceholder'"
                            [validationFunction]="validationFunctionError.bind(this, 'password', form)">
                            <ng-container errors *ngIf="hasFormControlRequiredErrors('password', form)">
                                <span class="centric-error-message">
                                    {{ 'register.passwordFormControlRequired' | translate }}
                                </span>
                            </ng-container>
                        </windmill-input>
                    </centric-form-control>
                </centric-form-item>
                <centric-alert [type]="getToasterType()">
                    <div message>
                        {{ 'alerts.passwordAlertRequirements1' | translate }} <br />
                        {{ 'alerts.passwordAlertRequirements2' | translate }}
                    </div>

                </centric-alert>
                <centric-form-item>
                    <centric-form-label>{{ 'genericFields.password.confirmPassword' | translate }}</centric-form-label>
                    <centric-form-control frontendRequiredInput>
                        <windmill-input ariaAttributes formControlName="confirmPassword" type="password"
                            [placeholder]="'genericFields.password.confirmPasswordPlaceholder' | translate"
                            [maxLength]="256"
                            [ariaLabelTranslation]="'genericFields.password.confirmPasswordPlaceholder'"
                            [validationFunction]="validationFunctionError.bind(this, 'confirmPassword', form)">
                            <ng-container errors>
                                <ng-container *ngIf="shouldDisplayPasswordConfirmationError()">
                                    <span class="centric-error-message">{{ getConfirmPasswordErrorMessage(form) |
                                        translate
                                        }} </span>
                                </ng-container>
                            </ng-container>
                        </windmill-input>
                    </centric-form-control>
                </centric-form-item>

                <centric-button class="form-button" [disabled]="!form.valid">{{
                    'general.button.confirm' | translate}}
                </centric-button>
            </form>
        </ng-container>
    </centric-set-new-password>
</ng-container>

<ng-template #successfullChangePassword>
    <section class="background-cover"></section>
</ng-template>