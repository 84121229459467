import { Inject, Injectable } from '@angular/core';
import { Environment } from '../_models/environment.model';
import { HttpClient } from '@angular/common/http';
import { ChangePassword } from '../_models/change-password.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChangePasswordService {

    constructor(@Inject('env') private environment: Environment, private httpClient: HttpClient) { }

    public changePassword(changePassword: ChangePassword): Observable<ChangePassword> {
        return this.httpClient.put<ChangePassword>(`${this.environment.apiPath}/user/recover/reset-password`, changePassword);
    }
}
