import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailConfirmationService, FormUtil, ModalData, RecoverPassword, RecoverPasswordService, commonRoutingConstants } from '@frontend/common';
import { RecaptchaComponent } from 'ng-recaptcha';
import { CustomDialogConfigUtil } from '../../_util/custom-dialog-config';
import { DialogService } from '@windmill/ng-windmill';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
	selector: 'frontend-email-action',
	templateUrl: './email-action.component.html',
	styleUrls: ['./email-action.component.scss']
})
export class EmailActionComponent implements OnInit {
	@ViewChild(RecaptchaComponent) ngRecaptcha!: RecaptchaComponent;

	public form: FormGroup;
	public emailValidator = FormUtil.validateEmail(false);
	public validationFunctionError = FormUtil.genericValidationFunctionError;
	public getEmailErrorMessage = FormUtil.getEmailErrorMessage;
	public shouldDisplaySuccessfulDialog = false;

	public get description(): string {
		return this.isConfirmationEmailComponent() ? 'register.accountConfirmation.validateEmailDescription'
			: 'forgotPassword.description';
	}

	public get title(): string {
		return this.isConfirmationEmailComponent() ? 'register.accountConfirmation.validateEmailTitle'
			: 'forgotPassword.title';
	}

	constructor(
		private recoverPasswordService: RecoverPasswordService,
		private emailConfirmationService: EmailConfirmationService,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private dialogService: DialogService
	) { }

	public ngOnInit(): void {
		this.initForm();
	}

	public isConfirmationEmailComponent(): boolean {
		const route = this.route.snapshot.url?.[0]?.path;
		return route === commonRoutingConstants.resendConfirmationEmail ?? false;
	}

	public sendEmail(): void {
		if (this.form.invalid) {
			return;
		}

		if (!this.isConfirmationEmailComponent()) {
			this.recoverPassword();
			return;
		}

		this.resendConfirmationEmail();

	}

	public navigateToLogin(): void {
		this.router.navigate([commonRoutingConstants.login]);
	}

	private resendConfirmationEmail(): void {
		const email = this.form.get('email')?.value;
		this.emailConfirmationService.resendConfirmationEmail(email).subscribe(
			() => {
				this.displaySuccessDialog();
			}
		);
	}

	private recoverPassword(): void {
		const recoverPasswordModel = new RecoverPassword(this.form.get('email')?.value, this.form.get('recaptcha')?.value);

		this.recoverPasswordService.recoverPassword(recoverPasswordModel).subscribe(
			{
				next: () => {
					this.displaySuccessDialog();
				},
				error: () => {
					this.ngRecaptcha.reset();
				}
			}
		);
	}

	private initForm(): void {
		const recaptchaValidators = this.isConfirmationEmailComponent() ? [] : [Validators.required];

		this.form = this.formBuilder.group({
			email: ['', [Validators.required, this.emailValidator]],
			recaptcha: ['', recaptchaValidators]
		});
	}

	private displaySuccessDialog(): void {
		this.shouldDisplaySuccessfulDialog = true;
		const dialogConfig = this.isConfirmationEmailComponent() ?
			this.getDialogConfig('register.accountConfirmation.confirmationSuccessful', 'general.success.confirmEmailText') :
			this.getDialogConfig('forgotPassword.recoverSuccessful', 'general.success.recoverPasswordText');
		this.showDialog(dialogConfig);
	}

	private getDialogConfig(titleKey: string, contentKey: string): MatDialogConfig {
		const modalData = new ModalData(titleKey, 'general.success.title', contentKey, '', 'modal.continueLogin', true, 'success', 'theme', 'verified.svg');
		return { ...CustomDialogConfigUtil.createMessageModal(modalData), disableClose: true };
	}

	private showDialog(dialogConfig: MatDialogConfig): void {
		this.dialogService.message(CustomDialogComponent, dialogConfig)?.afterClosed()
			.subscribe((response) => {
				if (!response) {
					return;
				}

				this.navigateToLogin();
			});
	}

}
