import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AppLoaderService {
	public loaderSubject = new Subject<boolean>();
	public loaderStateObservable$: Observable<boolean> = this.loaderSubject.asObservable();

	public loaderShow(value: boolean) {
		this.loaderSubject.next(value);
	}
}
