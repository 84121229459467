export enum GeneralInfoFormFields {
    companyName = 'companyName',
    email = 'email',
    ownerName = 'ownerName',
    catgeory = 'category',
    group = 'group',
    subcategory = 'subcategory',
    commerceNumber = 'commerceNumber',
    commerceMainLocation = 'commerceMainLocation',
    commercePostalAdress = 'commercePostalAdress',
    legalform = 'legalForm'

}