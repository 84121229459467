<frontend-table
	#supplierRequestTable
	class="table-scroll"
	[columns]="allColumns"
	[listLength]="dataCount"
	[showFooter]="true"
	[showHeader]="false"
	(actionButtonClicked)="onActionButtonClicked($event)"
	(loadDataEvent)="loadData($event)">
</frontend-table>
