<ng-container *ngIf="isDataExisting else noData">
	<frontend-table
		#grantsTable
		class="table-pannel"
		[columns]="allColumns"
		[hasCheckbox]="true"
		[listLength]="dataCount"
		[showFooter]="true"
		[showHeader]="true"
		(actionButtonClicked)="onActionButtonClicked($event)"
		(loadDataEvent)="loadData($event)">
		<ng-container>
			<span class="left-container">{{ 'general.pages.grants' | translate }} </span>
			<section class="right-container">
				<centric-button
					ariaAttributes
					type="button-default"
					iconId="plus_b"
					[ariaLabelTranslation]="'grants.createGrant'"
					(click)="openModal()">
					{{ 'grants.createGrant' | translate }}
				</centric-button>
			</section>
		</ng-container>
	</frontend-table>
</ng-container>

<ng-template #noData>
	<centric-panel class="table-pannel" [class.full-size-panel]="true" [showFooter]="false">
		<ng-container panel-header>
			<span class="left-container">{{ 'general.pages.grants' | translate }} </span>
		</ng-container>
		<ng-container panel-content>
			<frontend-no-data [noDataTitle]="'grants.noData.title'" [noDataDescription]="'grants.noData.description'">
				<centric-button type="button-default" iconId="plus_b" (click)="openModal()">
					{{ 'grants.createGrant' | translate }}
				</centric-button>
			</frontend-no-data>
		</ng-container>
	</centric-panel>
</ng-template>
