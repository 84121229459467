import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MunicipalityRoutingModule } from './municipality-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MunicipalityRoutingModule
  ]
})
export class MunicipalityModule { }
