import { EnumValueDto } from "./enum-value-dto.model";


export class DropdownDataFilterDto {
    public statuses: EnumValueDto[];
    public targets: EnumValueDto[];
    public offerTypes:  any;

    constructor(statuses: EnumValueDto[], targets: EnumValueDto[], offerTypes: EnumValueDto[]) {
        this.statuses = statuses;
        this.targets = targets;
        this.offerTypes = offerTypes;
    }
}