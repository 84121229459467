<frontend-table
	#suppliersTable
	class="table-scroll"
	[columns]="allColumns"
	[hasCheckbox]="true"
	[showFooter]="true"
	[showHeader]="false"
	[listLength]="dataCount"
	(actionButtonClicked)="onActionButtonClicked($event)"
	(loadDataEvent)="loadData($event)"></frontend-table>
