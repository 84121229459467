import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, GrantDto } from '@frontend/common';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GrantService {
	
	constructor(@Inject('env') private environment: Environment, private httpClient: HttpClient) {}

	public getAllGrants(): Observable<GrantDto[]> {
		return this.httpClient.get<GrantDto[]>(
			`${this.environment.apiPath}/grant`,
		);
	}

	public createGrant(grantDto: GrantDto): Observable<GrantDto> {
		return this.httpClient.post<GrantDto>(`${this.environment.apiPath}/grant`, grantDto);
	}

	public editGrant(grantDto: GrantDto): Observable<GrantDto> {
		return this.httpClient.patch<GrantDto>(`${this.environment.apiPath}/grant`, grantDto);
	}

	public getGrantsPaginated(page: number, size: number): Observable<GrantDto[]> {

		const httpParams = new HttpParams()
			.set('page', page)
			.set('size', size);

		return this.httpClient.get<GrantDto[]>(
			`${this.environment.apiPath}/grant/paginated`,
			{ params: httpParams }
		);
	}

	public countGrants(): Observable<number> {
		return this.httpClient.get<number>(
			`${this.environment.apiPath}/grant/count`,
		);
	}
}
