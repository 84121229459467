export enum FilterColumnKey {
    CHECKBOX = 'checkboxFilter',
    STATUS = 'statusFilter',
    TITLE = 'titleFilter',
    CITIZEN_OFFER_TYPE = 'citizenOfferTypeFilter',
    OFFER_TYPE = 'offerTypeFilter',
    GRANTS = 'grantsFilter',
    VALIDITY = 'validityFilter',
    ACTIONS = 'actionsFilter'
}
