import { Inject, Injectable } from '@angular/core';
import { Environment } from '../_models/environment.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EmailConfirmationService {

    constructor(@Inject('env') private environment: Environment, private httpClient: HttpClient) { }

    public resendConfirmationEmail(email: string): Observable<void> {
        const options = {
            params: { email: email }
        };

        return this.httpClient.get<void>(`${this.environment.apiPath}/user/resend-confirmation`, options);
    }
}
