<section mat-dialog-title>
	<h3>
		{{ 'passholders.assignGrants' | translate }}
	</h3>
	<button
		ariaAttributes
		mat-dialog-close
		class="close-button"
		[attr.ariaLabel]="'general.button.close' | translate"
		[ariaLabelTranslation]="'general.button.close'"
		(click)="closePopup()">
		<centric-icon2 ariaAttributes [svgIcon]="'cancel_b'" [size]="'16'"></centric-icon2>
	</button>
</section>
<section mat-dialog-content class="assign-table-container">
	<frontend-table
		#assignGrantTable
		[columns]="allColumns"
		[hasCheckbox]="true"
		[listLength]="dataCount"
		[showFooter]="false"
		(checkboxUpdated)="onCheckboxClicked($event)"
		(loadDataEvent)="loadGrants()"></frontend-table>
</section>
<section mat-dialog-actions>
	<centric-button type="button-link-dark" (click)="closePopup()">
		{{ 'general.button.cancel' | translate }}
	</centric-button>
	<centric-button (click)="saveGrants()">
		{{ 'general.button.save' | translate }}
	</centric-button>
</section>
