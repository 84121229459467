import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableColumn } from '@frontend/common';

@Component({
  selector: 'frontend-table-columns-manager',
  templateUrl: './table-columns-manager.component.html',
  styleUrls: ['./table-columns-manager.component.scss'],
})
export class TableColumnsManagerComponent {
  public tableColumns: TableColumn[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<TableColumnsManagerComponent>) {
    this.tableColumns = data.tableColumns;
  }

  public close(): void {
		this.dialogRef.close(false);
	}

	public accept(): void {
		this.dialogRef.close(this.tableColumns);
	}

}
