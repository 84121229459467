<ng-container *ngIf="isAllDataLoaded()">
	<centric-sidenav
		[menuItems]="navigationItems"
		[ariaLabelCloseButton]="'general.button.close' | translate"
		[(opened)]="opened">
		<section sidenav-header class="sidenav-header-section">
			<img src="/assets/images/LFL-logo.svg" alt="Logo image" class="pointer" (click)="navigateToDashboard()" />
			<h2 class="title">{{ tenantName }}</h2>
		</section>
		<section sidenav-footer>
			<centric-button iconId="logout-arrow_r" type="button-link" (click)="logout()">
				{{ 'general.logout' | translate }}
			</centric-button>
		</section>
	</centric-sidenav>
</ng-container>
