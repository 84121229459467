import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject, map, tap } from 'rxjs';
import { SupplierProfile } from '../_models/supplier-profile.model';
import { Environment } from '../_models/environment.model';
import { SupplierProfileDto } from '../_models/supplier-profile-dto.model';
import { ProfileDropdownsDto } from '../_models/profile-dropdowns-dto.model';

@Injectable({
    providedIn: 'root'
})
export class SupplierProfileService {

    public supplierProfileInformationSubject = new Subject<SupplierProfile>();
    public supplierProfileInformationObservable: Observable<SupplierProfile> =
        this.supplierProfileInformationSubject.asObservable();

    private _supplierProfileInformation: SupplierProfile;

    public get supplierProfileInformation(): SupplierProfile {
        return this._supplierProfileInformation;
    }

    public set supplierProfileInformation(value: SupplierProfile) {
        this._supplierProfileInformation = value;
    }

    constructor(@Inject('env') private environment: Environment, private httpClient: HttpClient) { }

    public getAllDropdownsData(): Observable<ProfileDropdownsDto> {
        return this.httpClient.get<ProfileDropdownsDto>(
            `${this.environment.apiPath}/supplier-profile/dropdown-data`,
        );
    }

    public updateSupplierProfile(supplierProfileDto: SupplierProfileDto): Observable<void> {
        return this.httpClient.put<void>(`${this.environment.apiPath}/supplier-profile`, supplierProfileDto);
    }

    public getSupplierProfile(supplierId: string): Observable<SupplierProfile> {

        return this.httpClient.get<SupplierProfile>(`${this.environment.apiPath}/supplier-profile/${supplierId}`)
            .pipe(
                tap((result) => {
                    this.supplierProfileInformation = result;
                    this.supplierProfileInformationSubject.next(result as SupplierProfile)
                }
                ),
                map((result) => { return result as SupplierProfile }
                ));
    }
}
