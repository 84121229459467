<centric-card [shadow]="true">
	<div centric-card-content class="info-widget">
		<div class="info-widget-icon">
			<centric-icon2
				[size]="'32'"
				[svgIcon]="icon"></centric-icon2>
		</div>
		<div class="info-widget-right">
			<p class="info-widget-title">{{ title }}</p>
			<p class="info-widget-value">{{ value }}</p>
		</div>
	</div>
</centric-card>