import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormUtil, ModalData, RejectionReason, SupplierProfileService, WarningDialogData } from '@frontend/common';
import { MunicipalitySupplierService } from '../../_services/suppliers.service';
import { DialogService, CentricCounterMessages } from '@windmill/ng-windmill';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RejectSupplierDto } from "../../_models/reject-supplier-dto.model";
import { CustomDialogComponent, CustomDialogConfigUtil } from "@frontend/common-ui";
import { TranslateService } from "@ngx-translate/core";


@Component({
	selector: 'frontend-supplier-review-popup',
	templateUrl: './supplier-review-popup.component.html',
	styleUrls: ['./supplier-review-popup.component.scss']
})
export class SupplierReviewPopupComponent implements OnInit {
	public rejectSupplierForm: FormGroup;
	public isRejecting = false;

	public reasonDropdownSource: string[] = [
		'Not in region',
		'Misbehavior',
		'Idle',
		'Incomplete information',
		'Duplicate'
	];

	public reasonUpdatedSource: string[] = [];

	public validationFunctionError = FormUtil.validationFunctionError;
	public hasFormControlRequiredErrors = FormUtil.hasFormControlRequiredErrors;
	public counterMessages: CentricCounterMessages = FormUtil.getTextAreaCounterMessages(this.translateService);

	private mapRejectionStringToEnum: Record<string, RejectionReason> = {
		'Not in region': RejectionReason.NOT_IN_REGION,
		'Misbehavior': RejectionReason.MISBEHAVIOR,
		'Idle': RejectionReason.IDLE,
		'Incomplete information': RejectionReason.INCOMPLETE_INFORMATION,
		'Duplicate': RejectionReason.DUPLICATE
	};

	constructor(
		private readonly dialogService: DialogService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private supplierService: MunicipalitySupplierService,
		private supplierProfileService: SupplierProfileService,
		private readonly dialogRef: MatDialogRef<SupplierReviewPopupComponent>,
		private formBuilder: FormBuilder,
		private translateService: TranslateService) {
	}

	public ngOnInit(): void {
		this.reasonUpdatedSource = this.reasonDropdownSource;
		this.initRejectSupplierForm();

		this.dialogRef.backdropClick().subscribe(() => {
			this.close();
		});
	}

	public close(value?: string): void {
		if (this.rejectSupplierForm.valid || this.rejectSupplierForm.get('rejectionComments')?.value != '') {
			this.openWarningModal();
			return;
		}

		this.dialogRef.close(value);
	}

	public rejectSupplier(): void {
		if (!this.supplierId) {
			return;
		}

		const rejectSupplierDto = this.createRejectSupplierDto(this.supplierId);

		this.supplierService.rejectSupplier(rejectSupplierDto)
			.subscribe(() => {
				this.dialogRef.close(this.supplierId);
			});
	}

	public setIsRejecting(value: boolean): void {
		this.isRejecting = value;
		this.dialogRef.updateSize(value ?  '790px' : '80%');
	}

	public approveSupplier(): void {
		if (!this.supplierId) {
			return;
		}

		this.supplierService.approveSupplier(this.supplierId).subscribe(
			() => {
				this.dialogRef.close(this.supplierId);
			}
		);
	}

	public onSearchValueChanged(event: string | null): void {
		this.reasonUpdatedSource =
			event === null
				? this.reasonDropdownSource
				: this.reasonDropdownSource.filter((item) => item.toLowerCase().includes(event.trim().toLowerCase()));
	}

	public openWarningModal(): void {
		const data = new WarningDialogData();

		this.dialogService.message(
			CustomDialogComponent,
			{
				...CustomDialogConfigUtil.createMessageModal(
					new ModalData(
						'general.warning',
						'',
						'rejectSupplier.leavingWarning',
						'general.button.stay',
						'general.button.cancel',
						false,
						'warning',
						'theme',
						'',
						data,
						true
					)),
				width: '400px'
			}
		)?.afterClosed()
			.subscribe(result => {
				if (result) {
					this.dialogRef.close(false);
				}
			});
	}

	private get supplierId() {
		return this.supplierProfileService.supplierProfileInformation?.supplierId;
	}

	private createRejectSupplierDto(supplierId: string): RejectSupplierDto {
		return new RejectSupplierDto(
			this.mapRejectionStringToEnum[this.rejectSupplierForm.get('rejectionReason')?.value],
			supplierId,
			this.rejectSupplierForm.get('rejectionComments')?.value
		)
	}

	private initRejectSupplierForm(): void {
		this.rejectSupplierForm = this.formBuilder.group(
			{
				rejectionReason: ['', [Validators.required]],
				rejectionComments: [''],
			}
		);
	}
}
