import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { commonRoutingConstants } from '../_constants/common-routing.constants';

export const nonAuthGuard = (next: ActivatedRouteSnapshot) => {
	const authService = inject(AuthService);
	if (!authService.isLoggedIn ) {
		return true;
	} 
	return createUrlTreeFromSnapshot(next, ['/', commonRoutingConstants.dashboard]);
};