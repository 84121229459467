import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CentricButtonModule,
  CentricCardModule,
  CentricDataGridModule,
  CentricDialogModule,
  CentricIcon2Module,
  WindmillTooltipModule,
  CentricPanelModule,
  WindmillCheckboxModule,
  CentricLoginModule,
  CentricRegisterModule,
  CentricComplexFormModule,
  WindmillInputModule,
  WindmillDropdownSearchModule,
  WindmillPaginatorModule,
  CentricMatTabsModule,
  CentricAlertModule,
  CentricUploadAreaModule,
  CentricLinkModule,
  CentricForgotPasswordModule,
  CentricSetNewPasswordModule,
  CentricChipsModule,
  CentricBreadcrumbsModule,
  CentricNotificationBadgeModule,
  WindmillDatePickerModule,
  CentricTextarea2Module,
  CentricSlideToggle2Module
} from '@windmill/ng-windmill';



@NgModule({
  imports: [CommonModule],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    WindmillDatePickerModule,
    CentricDataGridModule,
    CentricLoginModule,
    CentricRegisterModule,
    CentricAlertModule,
    CentricUploadAreaModule,
    CentricComplexFormModule,
    WindmillDropdownSearchModule,
    CentricCardModule,
    CentricButtonModule,
    CentricDialogModule,
    WindmillTooltipModule,
    CentricIcon2Module,
    CentricPanelModule,
    WindmillPaginatorModule,
    CentricMatTabsModule,
    WindmillCheckboxModule,
    WindmillInputModule,
    CentricTextarea2Module,
    CentricLinkModule,
    CentricForgotPasswordModule,
    CentricSetNewPasswordModule,
    CentricChipsModule,
    CentricBreadcrumbsModule,
    CentricNotificationBadgeModule,
    CentricSlideToggle2Module
  ]
})
export class WindmillModule {
  public static forRoot(): ModuleWithProviders<WindmillModule> {
    return {
      ngModule: WindmillModule,
    };
  }
}
