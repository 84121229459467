import { GenericStatusEnum } from "../_enums/generic-status.enum";

export class StatusUtil {

    public static mapStatusTranslationLabel(status: GenericStatusEnum): string {
        switch (status) {
            case GenericStatusEnum.ACTIVE:
                return 'status.active';
            case GenericStatusEnum.APPROVED:
                return 'status.approved';
            case GenericStatusEnum.REJECTED:
                return 'status.rejected';
            case GenericStatusEnum.EXPIRED:
                return 'status.expired';
            case GenericStatusEnum.PENDING:
                return 'status.pending';
            default:
                return '';
        }
    }

    public static getSvgIconForStatus(status: GenericStatusEnum): string {
        switch (status) {
            case GenericStatusEnum.ACTIVE:
            case GenericStatusEnum.APPROVED:
                return 'check-circle_b';
            case GenericStatusEnum.EXPIRED:
                return 'minus-circle_b';
            case GenericStatusEnum.REJECTED:
                return 'cancel-circle_b';
            case GenericStatusEnum.PENDING:
                return 'clock_b';
            default:
                return '';
        }
    }

    public static getColorClassForStatus(status: GenericStatusEnum): string {
        switch (status) {
            case GenericStatusEnum.ACTIVE:
            case GenericStatusEnum.APPROVED:
                return 'active';
            case GenericStatusEnum.EXPIRED:
                return 'expired';
            case GenericStatusEnum.REJECTED:
                return 'rejected';
            case GenericStatusEnum.PENDING:
                return 'pending';
            default:
                return '';
        }
    }
}