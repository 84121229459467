import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpLoaderFactory, CommonUiModule, WindmillModule } from '@frontend/common-ui';
import { APP_CONFIG } from '@frontend/app-config';
import { MunicipalityModule } from './municipality/municipality.module';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing.module';
import { environment } from '../environment/environment';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SuppliersListComponent } from './components/suppliers/suppliers.component';
import { CommonL4LModule, JwtInterceptor, DateAdapterModule } from '@frontend/common';
import { SupplierReqComponent } from './components/supplier-req/supplier-req.component';
import { ActiveSuppliersComponent } from './components/active-suppliers/active-suppliers.component';
import { SupplierReviewPopupComponent } from './components/supplier-review-popup/supplier-review-popup.component';
import { PassholdersComponent } from './pages/passholders/passholders.component';
import { ImportPassholdersComponent } from './components/import-passholders/import-passholders.component';
import { GrantsComponent } from './pages/grants/grants/grants.component';
import { CreateGrantComponent } from './components/create-grant/create-grant/create-grant.component';
import { AssignGrantComponent } from './components/assign-grant/assign-grant.component';
import { CentricTextarea2Module, WindmillDatePickerModule, CentricRadioModule } from '@windmill/ng-windmill';
import { SupplierDetailsComponent } from './components/supplier-details/supplier-details.component';
import { DatePipe } from '@angular/common';
import { OfferApprovalPopupComponent } from './components/offer-approval-popup/offer-approval-popup.component';
import { InviteSuppliersComponent } from './components/invite-suppliers/invite-suppliers.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InvitationsComponent } from './components/invitations/invitations.component';
import { OffersForMuniciaplityComponent } from './pages/offers-for-municipality/offers-for-municipality.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { CreateUserPopupComponent } from './components/create-user-popup/create-user-popup.component';
import { SuppliersMapComponent } from './components/suppliers-map/suppliers-map.component';

@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		SuppliersListComponent,
		SupplierReqComponent,
		ActiveSuppliersComponent,
		SupplierReviewPopupComponent,
		OfferApprovalPopupComponent,
		PassholdersComponent,
		ImportPassholdersComponent,
		AssignGrantComponent,
		GrantsComponent,
		CreateGrantComponent,
		SupplierDetailsComponent,
		OffersForMuniciaplityComponent,
		InviteSuppliersComponent,
		InvitationsComponent,
		UserManagementComponent,
		CreateUserPopupComponent,
		SuppliersMapComponent
	],
	imports: [
		AppRoutingModule,
		CommonUiModule.forRoot(environment),
		CommonL4LModule,
		WindmillModule,
		MunicipalityModule,
		MatTabsModule,
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		HttpClientModule,
		WindmillDatePickerModule,
		CentricTextarea2Module,
		CentricRadioModule,
		DateAdapterModule,
		RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		MatDialogModule
	],
	providers: [
		[DatePipe],
		{ provide: APP_CONFIG, useValue: environment },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
