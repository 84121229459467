<ng-container *ngIf="isDataExisting else noData">
	<frontend-table
		#passholdersTable
		class="table-pannel"
		[columns]="allColumns"
		[hasCheckbox]="true"
		[typeOfT]="typeOfModal"
		[columnWithChips]="'grants'"
		[listLength]="dataCount"
		[showFooter]="true"
		[showHeader]="true"
		(actionButtonClicked)="onActionButtonClicked($event)"
		(getSelectedItemsNumber)="onGetSelectedItemsNumber($event)"
		(loadDataEvent)="loadData($event)">
		<ng-container>
			<span class="left-container">{{ 'general.pages.passholders' | translate }} </span>
			<ng-container *ngIf="isDataExisting">
				<section class="right-container">
					<ng-container *ngIf="isMultipleSelect">
						<centric-button
							ariaAttributes
							type="button-link-dark"
							[ariaLabelTranslation]="'general.button.assign'"
							(click)="assignMultipleGrants()">
							{{ 'general.button.assign' | translate }}
						</centric-button>
					</ng-container>
					<centric-button
						ariaAttributes
						windmill-tooltip
						class="item"
						type="icon-button-fourth-dark"
						iconId="slider-vert_b"
						size="small"
						tooltipColor="dark-grey"
						tooltipPosition="above"
						[ariaLabelTranslation]="'modal.manageColumns.title'"
						[tooltipText]="'modal.manageColumns.title' | translate"
						(click)="manageColumns()"></centric-button>

					<centric-button
						ariaAttributes
						type="button-theme"
						iconId="filter_b"
						[ariaLabelTranslation]="'general.button.filter'">
						{{ 'general.button.filter' | translate }}
					</centric-button>

					<centric-button
						ariaAttributes
						type="button-default"
						iconId="plus_b"
						[ariaLabelTranslation]="'passholders.import'"
						(click)="openPassholdersModal()">
						{{ 'passholders.import' | translate }}
					</centric-button>
				</section>
			</ng-container>
		</ng-container>
	</frontend-table>
</ng-container>

<ng-template #noData>
	<centric-panel class="table-pannel" [class.full-size-panel]="true" [showFooter]="false" [header]="true">
		<ng-container panel-header>
			<span class="left-container">{{ 'general.pages.passholders' | translate }} </span>
		</ng-container>
		<ng-container panel-content>
			<frontend-no-data
				[noDataTitle]="'passholders.noData.title'"
				[noDataDescription]="'passholders.noData.description'">
				<centric-button type="button-default" iconId="plus_b" (click)="openPassholdersModal()">
					{{ 'passholders.import' | translate }}
				</centric-button>
			</frontend-no-data>
		</ng-container>
	</centric-panel>
</ng-template>
