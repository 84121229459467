import { NgModule } from '@angular/core';
import { MarkAsteriskDirective } from './_directives/asterisk.directive';
import { NumericInputDirective } from './_directives/numerical-input.directive';
import { ErrorCatchingInterceptor } from './_interceptors/error-catching.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AccesibilityModule } from '@innovation/accesibility';
import { AppHttpInterceptor } from './_interceptors/app-http.interceptor';

@NgModule({
	imports: [HttpClientModule, AccesibilityModule],
	declarations: [MarkAsteriskDirective, NumericInputDirective],
	exports: [MarkAsteriskDirective, NumericInputDirective, AccesibilityModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorCatchingInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppHttpInterceptor,
			multi: true
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		}
	]
})
export class CommonL4LModule {}
