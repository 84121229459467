export const commonRoutingConstants = {
    app: 'app',
    dashboard: 'dashboard',
    register: 'register',
    login: 'login',
    resetPassword: 'resetPassword',
    suppliers: 'suppliers',
    pendingRequests: 'pendingRequests',
    passholders: 'passholders',
    recover: 'recover',
    grants: 'grants',
    editProfile: 'profile/edit',
    supplierDetails: 'supplier-details/:id',
    supplierOffers:'supplier-details/:id/offers',
    resendConfirmationEmail: 'resend-confirmation-email',
    offers: 'offers',
    registrationSuccessful: 'registration-successful',
    userManagement: 'user-management'
};